import ContentLayout from '../components/ContentLayout'

const ContentThree = () => (
    <>
        {/* 
            (NOTE: PLEASE ONLY DELETE THIS COMMENT ONCE CONTENT EDITING IS FINISHED) 
            ↓↓↓ @CIARAN - BELOW IS WHERE YOU PUT IN YOUR MAIN TEXT CONTENT ↓↓↓
            (PASTE YOUR TEXT WHERE LOREM IPSUM RESIDES)
            (You can also edit the overall heading of the website /and/ the 
            respective section titles if you need to)
        */}
        <ContentLayout 
            heading="The Costs of Creation: What is a fair and desirable future for web monetisation?" 
            title="What do online workers and volunteers think?"
            text=
            {<>
                <p>Our research brought together people in the UK who are on low or no pay for their online work or volunteering, to collectively deliberate about what they thought a fair and desirable future for web monetisation would be.</p>
                <div class='expander'>
                    <h3 class='intext-heading'>Fairness</h3>
                    <p>Workers feel they have little control in the prevailing systems of web monetisation and broader conditions of online work. They are beholden to platform monetisation rules which they don’t understand, unsupported from platform’s themselves, isolated from other workers, and feel their efforts are often out of view from users. They generally have a mixture of financial and non-financial motivations for what they do, though some pursue it because online work is their most viable option, making questions of fairness all the more pressing.</p>
                    <p>The situation differs for online volunteers, whose primary interest is supporting the public goods they nurture and as such they tend to be resistant to monetisation. At the same time, they also recognise that reward is integral to maintaining these same communities and, particularly on reflection, they are not entirely averse to financial reward where it is appropriate and used carefully. Moreover, volunteers see that some already make financial gains within their communities that others do not, with the latter simply being exploited.</p>
                </div>
                <div class='expander'>
                    <h3 class='intext-heading'>Desirability</h3>
                    <p>Understanding what a desirable future looks like connected to participants’ motivations for online work and volunteering. Discussions brought out how determining motivations in both activities is complicated. Both can feature, directly or indirectly, financial, non-financial and mixed motivations, and while few need to work or volunteer online, the value of these activities to all was clear. Whatever people’s motivations, there was a desire to protect certain parts of the Web from monetisation, so that they can remain free for all. However, it was clear that determining what ought to be protected is more complicated than it first seems.</p>
                    <p>There was also a desire to protect the public goods of volunteer spaces from dangers posed by monetisation. At the same time, it was clear that all see reward more generally beyond monetisation as less clear cut, and see room for different kinds of reward in most spaces online. As such, people conceived of the topic less as a question of monetisation or no monetisation, but rather what is the right kind - or right kind of non-financial reward - for a given space.</p>
                </div>
                <div class='expander'>
                    <h3 class='intext-heading'>Overarching consensus</h3>
                    <p>We saw persistent, underlying consensus across the majority of online workers and volunteers when it comes to what a fair and desirable future for web monetisation would be. Moreover, this appeared to have increased with exposure to information on the topic and opportunity to deliberate upon it.</p>
                    <p>However, attitudes here are highly contingent as participants told us, given awareness and understanding of developments in this area, and changing perceptions of the value of one’s online work or volunteering. Moreover, web monetisation technologies are very much in the process of developing and so we can expect attitudes to continue to change. More opportunities for ongoing discussion on the future of web monetisation were supported by those we spoke to.</p>
                </div>
            </>}
        />
    </>
)


export default ContentThree;