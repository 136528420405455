import ContentLayout from '../components/ContentLayout'

const ContentTwo = () => (
    <>
        {/* 
            (NOTE: PLEASE ONLY DELETE THIS COMMENT ONCE CONTENT EDITING IS FINISHED) 
            ↓↓↓ @CIARAN - BELOW IS WHERE YOU PUT IN YOUR MAIN TEXT CONTENT ↓↓↓
            (PASTE YOUR TEXT WHERE LOREM IPSUM RESIDES)
            (You can also edit the overall heading of the website /and/ the 
            respective section titles if you need to)
        */}
        <ContentLayout 
            heading="The Costs of Creation: What is a fair and desirable future for web monetisation?" 
            title="Why does the future of web monetisation matter?"
            text=
            {<>
             <p>More and more, the financial cost of our digital lives is creeping into view. There is growing awareness that each casual ‘agree’ we give to a pop-upabout cookies represents, in essence, the paying of a fee. We know that the ads we block are making it tougher to keep some sites afloat. And as the wider social costs of our current digital economy become clear - from the exploitation of workers (online and off), to the designing of platforms to swallow up users’ attention - the sustainability of the prevailing online business models is increasingly under scrutiny. All this makes the question of how the online world ought to be financed ever more pressing.</p>

<p>Answering this means wrestling with further complexities in turn: what would it mean to pay people fairly for their goods, services, creations or labours online? Are the current forms of funding, from advertising and gig work, to subscriptions and donations, to e-commerce and product placements, the best we can do, or are there better alternatives? And as the drive to “grind” and hustle in the digital world is ever more present, what shouldn’t be paid for or considered work online anyway?</p>

<p>Such technologies could offer a route to a digital economy in which people exercise more control over web monetisation, reducing the need to rely on external funding sources (such as advertisers) or direct user funding that is more disruptive (such as paywalls). However, it is possible this would encourage monetisation of things that ought to remain as they are, and could be co-opted by Big Tech companies largely to their gain. Whether or not this is true and whether it is this technology, another, or a set of monetisation tools that will become dominant, remains to be seen.</p>

<p>What is certain is that from micropayments to adverts, donations to subscriptions, all forms of web monetisation shape our online experiences, from introducing or reducing friction to our browsing, to deciding what it is we see. Likewise, all of these shape how people work online and what activities they can monetise in the first place. How we pay for our online world can have, therefore, tremendous ramifications.</p>
            </>}
        />

    </>
)


export default ContentTwo;
